import * as React from "react";
import { graphql } from "gatsby";

import Newhero from "../components/home/hero";
import Featurs from "../components/home/feature";
import Services from "../components/home/services";
import PromiseSection from "../components/home/promise";
import Testomonial from "../components/home/testomonial";
import Contactus from "../components/home/contactus";
import Footer from "../components/footer";
import ContactCta from "../components/cta/contactCta";
import GoTop from "../components/goTop";
import WhatsappButton from "../components/whatsapp";
import Seo from "../components/seo";

const IndexPage = ({ data }) => {
  const description = `  Our Early Childhood Center is
    KHDA approved with EYFS British Curriculum in AI Mankhool.
  The center is equipped with firendly & qualified staff, excellent
  facilities and a healthy, safe and secure environment.`;

  const { contactUs, hero, missionAndVision, promise, services } =
    data.gcms.homepages[0];

  const { navigation } = data.gcms.links[0];

  return (
    <>
      <Seo description={description} title={`Home`} />
      <GoTop />
      <Newhero
        navigation={navigation}
        title={hero.herotitle}
        description={hero.description}
      />
      <Services service={services[0]} subService={services.slice(1)} />
      <Featurs
        feature={missionAndVision[0]}
        subFeature={missionAndVision.slice(1)}
      />
      <PromiseSection promise={promise[0]} subPromises={promise.slice(1)} />
      {/* <Testomonial /> */}
      <ContactCta />
      <Contactus contactDetail={contactUs} />
      <WhatsappButton />
      <Footer navigation={navigation} />
    </>
  );
};

export const query = graphql`
  query MyQueryAr {
    gcms {
      homepages(locales: ar_AE) {
        hero {
          herotitle
          description
        }
        services {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_ServicesDetail {
            id
            service
            timing
          }
          ... on GraphCMS_SubBasicComponent {
            subTitle
            subDescription
          }
        }
        missionAndVision {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_SubBasicComponent {
            subTitle
            subDescription
          }
        }
        promise {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_SubBasicComponent {
            id
            subTitle
            subDescription
          }
        }
        contactUs {
          title
          description
          address
          phoneNumber
          email
        }
      }
      links(locales: ar_AE) {
        navigation {
          slug
          name
          id
        }
      }
    }
  }
`;

export default IndexPage;
